<template>
  <div class="card text-center">
    <router-link :to="{ path: this.dynamicContent.metadata.link }">
      <img
        v-lazy="
          this.dynamicContent.metadata.image.imgix_url +
          '?w=290&h=180&fit=clamp&auto=mode'
        "
        alt="Day Highlights"
        width="290px"
        height="180px"
        class="card-animation-on-hover"
        style="
          margin-top: -10px;
          opacity: 0.8;
          z-index: 12;
          box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px;
        "
      />
    </router-link>
    <br />

    <div
      class="text-left"
      style="margin-top: 10px; padding: 1px"
      v-html="this.dynamicContent.content"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'ClubActivity',
  created() {
    this.$store.dispatch('getDynamicContent');
  },
  computed: {
    dynamicContent: {
      get() {
        return this.$store.state.dynamicContent;
      },
    },
  },
};
</script>
